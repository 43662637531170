import {requestConfig, PublishMessage, PageConfig} from './requestConfig'

export function getnotedetail(path) {
    return requestConfig(path)
}

export function PostMessage(path, data) {
    return PublishMessage(path, data)
}

export function PageSizeChange(path, parmas) {
    return PageConfig(path, parmas)
}

const userApi = {
    // Social login
    SocialLoginUrl: '/auth-api/oauth/render/',
    SocialLoginCallback: '/auth-api/oauth/callback/',
    // Social bind
    SocialBindMobile: '/gitegg-oauth/social/bind/mobile',
    SocialBindAccount: '/gitegg-oauth/social/bind/account',
}

export function getSocialLoginUrl(socialType) {
    return getnotedetail(userApi.SocialLoginUrl + socialType);
}

export function getSocialLoginCallback(socialType,parameter) {
    return PageSizeChange(userApi.SocialLoginCallback + socialType,parameter);
}
export function socialLoginCallback(socialType, parameter) {
    return PostMessage(userApi.SocialLoginCallback + socialType, parameter);
}
